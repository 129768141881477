import web from "../src/images/Merc.jpg";
import app from "../src/images/Renault.jpg";
import android from "../src/images/Toyota1.jpg";
import digital from "../src/images/Suzuki.jpg";
import marketing from "../src/images/Haval.jpg";
import software from "../src/images/vw.jpg";

const Sdata = [
  { imgsrc: web, title: "Mercedes-AMG A45 4M" },
  { imgsrc: app, title: "Renault" },
  { imgsrc: software, title: "Volkswagen" },

  { imgsrc: android, title: "Toyota" },
  { imgsrc: digital, title: "Suzuki Swift" },
  { imgsrc: marketing, title: "Haval" }
];
export default Sdata;
