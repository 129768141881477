import React, { Component } from 'react'
// import logo from './images/pnp.png'
import { FaHome } from 'react-icons/fa'
import { Link } from 'react-router-dom'


export default class Navbar extends Component {
    state = {
        isOpen: false
    }
    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen })

    }
    render() {
        return (
            <nav className="navbar">
                <div className="nav-center">

                    <div className="nav-header">
                        <Link to="/">
                            {/* <img src={logo} alt="Property Marketing" /> */}
                            <h1> Pillar To Post </h1>
                        </Link>
                        <button type="button" className="nav-btn" onClick={this.handleToggle}>
                            <FaHome className="nav-icon" />
                        </button>
                    </div>
                    <ul className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}>
                        <li>
                            <Link to="/">Home</Link>
                        </li>

                        <li>
                            <Link to="/service">Service</Link>
                        </li>

                        <li>
                            <Link to="/contact">Contact us</Link>
                        </li>
                        <li>
                            <Link to="about">About us</Link>
                        </li>

                    </ul>


                </div>
            </nav>
        );
    }
}
