import React from "react";
// import web from "../src/images/img1.gif";
import { NavLink } from "react-router-dom";
const Pages = (props) => {
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid nav-bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 flex flex-column justify-content-center">
                  <h1>
                    {props.name}
                    <strong className="brand-name"> where it all started </strong>
                  </h1>
                  <h3 className="my-3">
                  Delivering new solutions.
                  </h3>
                  <p className="my-3">
                  Our Brand began with a few questions, after buying a car online with the exact spec I wanted, it had to be shipped from a Paarlberg dealer to Pretoria. 
                  I asked myself, Why can’t we have a time efficient way of cars being delivered on time in mint condition to clients? 
                  there is alot of deliveries for dealerships to handle at a time, 
                  so we came up with a solution to fill the gap between meeting the clients’ expectations and keeping the dealers promise at the same time.
                  </p>
                  <p className="my-3">
                  This led to the birth of Pillar to Post Auto Transportation. 
                  We aim to solve the challenges of dealerships having to meet customer expectations by offering solutions to assist with driver’s and 
                  alternative means to get cars to clients across the country in their respective areas without having to take the time of sales consultant.
                  We are a technological and paperless car delivery company that connects thousands of customers to their brand-new cars.
                  </p>
                  <h2 className="my-3">
                  Who are we?
                  </h2>
                  <p className="my-3">
                  Pillar to Post is a vehicle delivery company that bridges the gap between the dealer and customer in getting their cars, 
                  we connect thousands of people to their brand-new cars. We empower communities across the country by providing jobs to ensure a driver is always a call away
                  </p>
                  <h2 className="my-3">
                  Customers
                  </h2>
                  <p className="my-3">
                  We work with all dealerships throughout the country and guarantee vehicle delivery at your fingertips, 
                  we have modernized and simplified the process of getting your vehicle delivered to you all at your fingertips
                  </p>
                  <h2 className="my-3">
                  Become a dealer Partner
                  </h2>
                  <p className="my-3">
                  Get flexibility, convenience all on your schedule and own terms. 
                  We have a variety of options from trailer to flatbed deliveries or by one of our services champions and products geniuses
                  </p>
                  <h2 className="my-3">
                  How we empower
                  </h2>
                  <p className="my-3">
                  We hire individuals from all walks of life irrespective of their background. 
                  We provide the training at absolutely no expense as part of our commitment to push for greater inclusivity.
                  </p>
                  <h2 className="my-3">
                  Our Values
                  </h2>
                  <p className="my-3">
                  Commitment. Passion. Empowerment.
                  </p>
                  
                  <div className="mt-3">
                    <NavLink to={props.visit} className="btn-get-started">
                      {props.btnname}
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 header-img">
                  <img
                    src={props.imgsrc}
                    className="img-fluid animated"
                    alt="about pic"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Pages;
