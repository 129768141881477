import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';

//

const Contact = () => {
  const [data, setData] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: ""
  });

  const InputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value
      };
    });
  };

  // const formSubmit = (e) => {
  //   e.preventDefault();
  //   alert(`Your data has been submitted. ${data.fullname}`);
  // };

  function formSubmit(e) {
    e.preventDefault();

    emailjs.sendForm('')
        .then((result) => {
            console.log(result.text);
            Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
            })
        },
            (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, please enter the correct details',
                    text: error.text,
                })
            })
    e.target.reset()
};

  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Contact Us</h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 mx-auto col-10">
            <form onSubmit={formSubmit}>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  FullName
                </label>
                <input
                  required
                  type="text"
                  class="form-control"
                  name="fullname"
                  value={data.fullname}
                  onChange={InputEvent}
                  id="exampleFormControlInput1"
                  placeholder="Enter your name"
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Phone
                </label>
                <input
                  type="number"
                  class="form-control"
                  name="phone"
                  value={data.phone}
                  onChange={InputEvent}
                  id="exampleFormControlInput1"
                  placeholder="Enter your phone number"
                />
              </div>

              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Email address
                </label>
                <input
                  required
                  type="email"
                  class="form-control"
                  name="email"
                  value={data.email}
                  onChange={InputEvent}
                  id="exampleFormControlInput1"
                  placeholder="Enter your email address"
                />
              </div>

              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">
                  Message
                </label>
                <textarea
                  class="form-control"
                  name="message"
                  value={data.message}
                  onChange={InputEvent}
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>

              <div className="col-12">
                <button className="btn btn-outline-primary " type="submit">
                  Submit Form
                </button>
              </div>

              <div>
                <br></br>
              <h7>Address: 28 Klien Street
                Lakefield
                Benoni
                1501</h7>
              <h7> Johannesburg, 2000</h7> <br />
              <h7>Email address: <a href="mailto:info@pillartoposttransportation.co.za">PillarToPostTransportation.co.za</a></h7><br />
              <h7>Phone: <a href="tel:0000000000">0000000000</a></h7>

            </div>
            <br></br>
            </form>
            


          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
