import React from "react";
import web from "../src/images/img1.jpg";
// import { NavLink } from "react-router-dom";
import Pages from "./Pages";
const About = () => {
  return (
    <>
      <Pages
        name="About Us"
        imgsrc={web}
        visit="/contact"
        btnname="Contact Now"
      />
    </>
  )
};
export default About;
